<template>
  <div class="home">

    <div class="hype-container" v-show="hypeTrain">

      <div class="hype-content">

        <template>
          <div class="header">
            <span class="hype-lvl">LVL {{ currentLevel.id + 1 }}</span>
            <span class="title">Chute de Neige</span>

            <span class="time">{{ expire }}</span>
          </div>

          <div class="completion"></div>

          <div class="content">

            <div class="description">
              Les boules offertes, permettent d'atteindre les niveaux suivant.
              <div class="subtitle" v-if="currentLevel.id < levels.length - 1">
                {{ new Intl.NumberFormat().format(currentLevel.quantity - totalBoules) }} boules pour atteindre le niveau {{ currentLevel.id + 1 }}.
              </div>
              <div class="subtitle" v-else>
                {{ new Intl.NumberFormat().format( totalBoules) }} boules distribuer.
              </div>
            </div>

            <div class="purcentage">
              {{ Math.round((totalBoules - (currentLevel.id > 0 ? levels[currentLevel.id - 1].quantity : 0)) * 100 / (currentLevel.quantity - (currentLevel.id > 0 ? levels[currentLevel.id - 1].quantity : 0))) }}%
            </div>

          </div>

        </template>

      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import { auth, db } from '../firebase'
import { mapState } from 'vuex'
import moment from 'moment-timezone'

import { TweenMax, TimelineMax } from 'gsap'

import 'moment-countdown';

moment.locale('fr');  

function pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

const refreshTime = 5 * 60 // 5 minutes

const levels = [
  { id: 0, quantity: 500 },
  { id: 1, quantity: 1000 },
  { id: 2, quantity: 2000 },
  { id: 3, quantity: 3500 },
  { id: 4, quantity: 5000 },
]

export default {
  name: 'Classement',

  firestore() {
    return {
      events: db.collection('events').orderBy('data.date', 'desc'),
    }
  },

  data () {
    return {
      events: [],

      hypeTrain: false,

      hype: [],

      uniques: [],

      train: {
        level: 1,
        expire: null,
      },

      currentLevel: levels[0],

      levels,

      lastEvent: moment().add(refreshTime, 'seconds'),

      remindTrain: null,

      requiredEvent: 3,

      currentTime: moment(),
    }
  },

  methods: {

    startTrain () {
      console.log('--- START TRAIN ---')

      const diff = (this.currentLevel.id > 0 ? levels[this.currentLevel.id - 1].quantity : 0)
      const percent = (this.totalBoules - diff) * 100 / (this.currentLevel.quantity - diff)

      TweenMax.set('.hype-container', { width: 0 })
      TweenMax.set('.hype-content', { opacity: 0 })
      TweenMax.set('.completion', { width: 0 })

      this.hypeTrain = true

      TweenMax.to('.hype-container', 1, { width: 340 })
      TweenMax.to('.hype-content', 1, { opacity: 1 })
      TweenMax.to('.completion', 1, { width: `${percent}%` })

      this.train.expire = moment().add(refreshTime, 'seconds')
    },

    stopTrain () {
      console.log('--- STOP TRAIN ---')

      this.hype = []
      this.currentLevel = levels[0]

      TweenMax.to('.completion', 1, { width: `0%` })
      TweenMax.to('.hype-content', 1, { opacity: 0 })
      TweenMax.to('.hype-container', 1, { width: 0 })

      setTimeout(_ => {
        this.hypeTrain = false
      }, 1000)
    },

    onGift (gift) {
      const total = this.totalBoules
      const level = levels.find(x => x.quantity > total) || levels[this.levels.length - 1]

      if (level.id !== this.currentLevel.id) {
        this.train.expire = moment().add(refreshTime, 'seconds')
      }

      this.currentLevel = level

      const diff = (this.currentLevel.id > 0 ? levels[this.currentLevel.id - 1].quantity : 0)
      let percent = (this.totalBoules - diff) * 100 / (this.currentLevel.quantity - diff)

      if (percent > 100) percent = 100

      TweenMax.to('.completion', 1, { width: `${percent}%` })
    }

  },

  computed: {

    remind () {
      const d = this.currentTime;

      // eslint-disable-next-line no-undef
      const c = countdown(this.lastEvent)
      return `${pad(c.minutes, 2)}:${pad(c.seconds, 2)}`
    },

    expire () {
      const d = this.currentTime;

      if (!this.train.expire) return '00:00'

      // eslint-disable-next-line no-undef
      const c = countdown(this.train.expire)

      if (c.minutes === 0 & c.seconds === 0) {
        return this.stopTrain()
      }

      return `${pad(c.minutes, 2)}:${pad(c.seconds, 2)}`
    },

    totalBoules () {
      return this.hype.reduce( (acc, value) => acc + value.quantity, 0)
    },

  },

  created () {

    setInterval(_ => {
      this.currentTime = moment()
    }, 1000)

    this.timeout = setTimeout(_ => {
      if (this.hypeTrain) return
      this.hype = []

      console.log('Refresh')
    }, refreshTime * 1000)

    db.collection('events')
      .orderBy('data.date', 'desc').limit(1)
      .onSnapshot(event => {
        const { data } = event.docs[0].data()

        this.hype.push(data)
        if (!this.uniques.find(x => x.gifter === data.gifter)) {
          this.uniques.push(data)
        }

        this.onGift(data)

        if (data.quantity < 100) {
          console.log('receive ' + data.quantity)
          return;
        }

        clearTimeout(this.timeout)
        this.timeout = setTimeout(_ => {
          if (this.hypeTrain) return
          this.hype = []

          console.log('Refresh')
        }, refreshTime * 1000)

        this.lastEvent = moment().add(refreshTime, 'seconds')

        const hypeEvent = this.uniques
          .filter(x => x.quantity >= 100)

        console.log(this.uniques)

        console.log(hypeEvent.length)

        if (hypeEvent.length >= 3 && !this.hypeTrain) {
          this.startTrain()
        }
      })

  }


}

</script>

<style lang="scss">
body {
  background: transparent !important;
}
</style>

<style lang="scss" scoped>

.level-completion {
  display: flex;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  z-index: 1;
  color: white;
}


.hype-container {
  position: relative;

  overflow: hidden;

  margin: 24px;

  display: flex;
  flex-direction: column;

  width: 340px;
  height: 114px;

  background: #18181b;

  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0,0,0,.4),0 0 4px rgba(0,0,0,.4);
  border: 1px solid #ffffff1a;

}

.hype-content {
  position: relative;

  display: flex;
  flex-direction: column;

  padding: 5px 20px;

  width: 340px;
  height: 114px;
}

.completion {
  position: absolute;

  border-radius: 3px;

  top: 0;
  left: 0;

  height: 100%;
  width: 0%;

  // transition: 2s ease all;

  background: #008487;
}

.content {
  z-index: 1;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  color: rgb(239, 239, 241);

  height: 80px;  

  .purcentage {
    font-size: 24px;
    font-weight: 600;
  }

  .description {
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;

    max-width: 70%;

    .subtitle {
      font-size: 11px;
      white-space: nowrap;
      margin-top: 5px;
    }
  }
}

.header {
  z-index: 1;

  display: flex;
  flex-direction: row;

  color: rgb(239, 239, 241);

  .time {
    color: #B9BBBE;

    margin-left: 90px;

    font-size: 14px;
    font-weight: 400;
  }

  .hype-lvl {
    padding: 0 10px;

    border-radius: 25px;

    display: flex;
    align-items: center;
    justify-content: center;

    line-height: 18px;

    text-transform: uppercase;
    background: rgba(0,0,0,.6);
    font-family: Helvetica Neue,Helvetica,sans-serif;
    font-weight: 600;
    font-size: 12px;
  }

  .title {
    margin-left: 12px;

    font-size: 14px;
    font-weight: 600;
  }
}


</style>

